import { TRADE_PATHS } from '@constants/index';
import { getRate } from '@utils/getRate';
import helper from '@utils/helper';
// import { store } from '../../../store';

const getSpotMarketItems = (params) => {
  const { marketStore, listType, isLogin, type, enteredOptional } = params;
  let spotMarketItems = marketStore.marketSymbols || [];
  if (listType === 'favoriteList') {
    const favoriteList =
      marketStore?.rootStore?.tradeStore?.favoriteObject?.symbols || []; // 自选list
    // 如果第一次进入自选列表，并且没有自选list为空，则展示推荐list
    spotMarketItems =
      !favoriteList.length && !enteredOptional
        ? marketStore.recommendList // 推荐list
        : favoriteList;
    // 如果没有登录直接展示推荐list
    if (!isLogin) spotMarketItems = marketStore.recommendList;

    spotMarketItems = spotMarketItems.map((item) => {
      // 从市场list里面过滤出自选list、
      return marketStore.marketSymbols.find((i) => i.coinCode === item.symbol);
    });
  }
  if (type !== 'ALL') {
    spotMarketItems = spotMarketItems.filter(
      (item) => item.coinCode.split('_')[1] === type
    );
  }
  return spotMarketItems;
};

export const getListData = (type, marketStore, options = {}) => {
  // type: 'ALL' | 'USDC' | 'USDT' | 'HKD'
  const { listType, enteredOptional, isLogin } = options;
  const rateType = getRate();
  const spotMarketItems = getSpotMarketItems({
    listType,
    enteredOptional,
    isLogin,
    type,
    marketStore
  });
  const coinMap = marketStore?.marketCoins || [];
  const coinDetail = marketStore?.coinDetail;
  const otherRateDetail = marketStore?.otherRateDetail;
  const resData = [];
  spotMarketItems.forEach((itemData = {}) => {
    const { riseAndFall, priceLast } = itemData;
    const coinArr = itemData?.coinCode?.split('_') ?? [];
    let picturePath = '';
    coinMap.forEach((_item) => {
      if (_item.coinCode === coinArr[0]?.toUpperCase()) {
        picturePath = _item.picturePath;
      }
    });
    const symbol = helper.getCoinDetail(
      itemData?.coinCode,
      marketStore.marketSymbols
    );
    const precision = symbol?.symbolPrecision;
    const currentData = getListDataCurrentData({
      coinDetail,
      rateType,
      coinArr,
      priceLast,
      precision,
      otherRateDetail
    });
    const currentDataText = getListDataCurrentText({
      riseAndFall,
      rateType,
      precision,
      priceLast,
      coinArr,
      currentData
    });
    const formatPriceLast =
      typeof itemData.riseAndFall === 'undefined'
        ? '--'
        : helper.formatFixNumString(itemData.priceLast, precision);
    itemData = {
      ...itemData,
      picturePath,
      currentData,
      currentDataText,
      formatPriceLast
    };
    resData.push(itemData);
  });
  return resData;
};

export const getListDataCurrentData = ({
  coinDetail,
  rateType,
  coinArr,
  priceLast,
  precision,
  otherRateDetail
}) => {
  if (coinDetail && coinDetail.priceLast) {
    if (rateType === 'HKD') {
      if (coinArr[1] === 'HKD') {
        return typeof priceLast === 'undefined'
          ? '--'
          : helper.formatFixNumString(priceLast, precision);
      } else {
        return typeof priceLast === 'undefined'
          ? '--'
          : helper.formatFixNumString(
            priceLast *
                Number(otherRateDetail && otherRateDetail.priceLast) *
                Number(coinDetail.priceLast),
            precision
          );
      }
    } else {
      if (coinArr[1] === 'HKD') {
        return typeof priceLast === 'undefined'
          ? '--'
          : helper.formatFixNumString(
            (priceLast /
                Number(otherRateDetail && otherRateDetail.priceLast)) *
                Number(coinDetail.priceLast),
            precision
          );
      } else {
        return typeof priceLast === 'undefined'
          ? '--'
          : helper.formatFixNumString(
            priceLast * Number(coinDetail.priceLast),
            precision
          );
      }
    }
  }
};

const getListDataCurrentText = ({
  riseAndFall,
  rateType,
  priceLastm,
  precision,
  priceLast,
  coinArr,
  currentData
}) => {
  if (typeof riseAndFall === 'undefined') {
    return getRateSymbol(rateType);
  } else {
    if (coinArr[1] === rateType) {
      return `${getRateSymbol(rateType)}${helper.formatFixNumString(
        priceLast,
        precision
      )}`;
    } else {
      return `${getRateSymbol(rateType)}${currentData}`;
    }
  }
};

export const getRateSymbol = (rateType) => {
  return rateType === 'HKD' ? '≈HK$ ' : '≈$ '
}

export const jumpPage = (router, coinCode, pageType = 'kiki') => {
  if (coinCode.split('_').length !== 2) {
    // kiki新币列表部分币种不跳转交易详情
    return;
  }
  router.push({
    pathname: `${TRADE_PATHS.TRADE}/${coinCode}`
  });
};

export const handleZoneList = ({ marketStore, dataList }) => {
  const { marketSymbols } = marketStore;
  const coinDetail = marketStore?.coinDetail;
  const otherRateDetail = marketStore?.otherRateDetail;
  const coinMap = marketStore?.marketCoins || [];

  const plateDataList = dataList.map((itemData) => {
    const coinArr = itemData?.symbol?.split('_') ?? [];
    const symbol = helper.getCoinDetail(itemData?.symbol, marketSymbols);
    const precision = symbol?.symbolPrecision;
    const picturePath = coinMap.find(
      (i) => i.coinCode === coinArr[0].toUpperCase()
    )?.picturePath;
    const rateType = getRate();
    const currentData = getListDataCurrentData({
      coinDetail,
      rateType,
      coinArr,
      priceLast: symbol.priceLast,
      precision,
      otherRateDetail
    });
    const currentDataText = getListDataCurrentText({
      riseAndFall: symbol.riseAndFall,
      rateType,
      precision,
      priceLast: symbol.priceLast,
      coinArr,
      currentData
    });
    const formatPriceLast = typeof symbol.riseAndFall === 'undefined'
      ? '--'
      : helper.formatFixNumString(symbol.priceLast, precision);

    return ({
      ...itemData,
      riseAndFall: symbol?.riseAndFall,
      coinCode: symbol?.coinCode,
      volume: symbol?.volume,
      picturePath: picturePath,
      formatPriceLast: formatPriceLast,
      currentData: currentData,
      currentDataText: currentDataText
    })
  });
  return plateDataList;
};

// const getCurrentData = ({
//   coinDetail,
//   rateType,
//   coinArr,
//   itemData,
//   precision,
//   otherRateDetail
// }) => {
//   if (coinDetail && coinDetail.priceLast) {
//     if (rateType === 'HKD') {
//       if (coinArr[1] === 'HKD') {
//         return typeof itemData.price === 'undefined'
//           ? '--'
//           : helper.formatFixNumString(itemData.price, precision);
//       } else {
//         return typeof itemData.price === 'undefined'
//           ? '--'
//           : helper.formatFixNumString(
//             itemData.price *
//                 Number(otherRateDetail?.priceLast) *
//                 Number(coinDetail.priceLast),
//             precision
//           );
//       }
//     } else {
//       if (coinArr[1] === 'HKD') {
//         return typeof itemData.price === 'undefined'
//           ? '--'
//           : helper.formatFixNumString(
//             (itemData.price / Number(otherRateDetail?.priceLast)) *
//                 Number(coinDetail.priceLast),
//             precision
//           );
//       } else {
//         return typeof itemData.price === 'undefined'
//           ? '--'
//           : helper.formatFixNumString(
//             itemData.price * Number(coinDetail.priceLast),
//             precision
//           );
//       }
//     }
//   }
// };

// const getCurrentDataText = ({
//   price,
//   rateType,
//   coinArr,
//   priceLast,
//   precision,
//   currentData
// }) => {
//   let currentDataText = '';
//   if (typeof price === 'undefined') {
//     currentDataText = rateType === 'HKD' ? '≈HK$ ' : '≈$ ';
//   } else {
//     if (coinArr[1] === rateType) {
//       currentDataText = `${
//         rateType === 'HKD' ? '≈HK$ ' : '≈$ '
//       }${helper.formatFixNumString(priceLast, precision)}`;
//     } else {
//       currentDataText = `${rateType === 'HKD' ? '≈HK$ ' : '≈$ '}${currentData}`;
//     }
//   }
//   return currentDataText;
// };
