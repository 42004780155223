import React from 'react';

import { useRouter } from 'next/router';

import { MARKET_PATHS, TRADE_PATHS } from '@constants/index';

import ListH5 from './ListH5';
import ListWeb from './ListWeb';

import styles from './index.module.css';

export default function TradeList (props) {
  const {
    loading,
    page = 'MARKET_PAGE',
    dataSource = [],
    marketStore,
    isEdit,
    emptyText = '',
    type,
    isRecommendList,
    ...rest
  } = props;
  // page: 'ZONES_PAGE' | 'MARKET_PAGE' | 'HOME_HOT' | 'HOME_NEW' 板块详情 市场列表 首页-热门币种 首页-kiki新币
  const showFavotiteRoute = [
    MARKET_PATHS.MARKET,
    TRADE_PATHS.TRADE,
    `${MARKET_PATHS.ZONES}/[categoryKey]`,
    `${TRADE_PATHS.TRADE}/[coinCode]`
  ];
  const router = useRouter();
  const showFavorite = showFavotiteRoute.some((i) => i === router.route);
  return (
    <div className={styles.dataListContainer}>
      {type !== 'drawer' && (
        <ListWeb
          page={page}
          loading={loading}
          dataSource={dataSource}
          marketStore={marketStore}
          isEdit={isEdit}
          emptyText={emptyText}
          showFavorite={showFavorite && !isRecommendList}
          {...rest}
        />
      )}
      <ListH5
        page={page}
        loading={loading}
        dataSource={dataSource}
        marketStore={marketStore}
        isEdit={isEdit}
        emptyText={emptyText}
        type={type}
        showFavorite={showFavorite && !isRecommendList}
        {...rest}
      />
    </div>
  );
}
